import _ from 'lodash'
import CityPicker from '../cityPicker'
import Login from '../login'
import ReviewMap from '../reviewMap'
import Signout from '../signout'
import { authedComponent } from './authorizedRoute'

const routes = [
  {
    path: '/nyc',
    component: ReviewMap,
    props: { startLatitude: 40.762645, startLongitude: -73.981686, city: 'nyc' }
  },
  {
    path: '/london',
    component: ReviewMap,
    props: { startLatitude: 51.513405, startLongitude: -0.124925, city: 'london' }
  },
  {
    path: '/boston',
    component: ReviewMap,
    props: { startLatitude: 42.357227, startLongitude: -71.059231, city: 'boston' }
  },
  {
    path: '/cyprus',
    component: ReviewMap,
    props: { startLatitude: 34.9371989, startLongitude: 32.9629145, city: 'cyprus' }
  },
  {
    path: '/gibraltar',
    component: ReviewMap,
    props: { startLatitude: 36.1296275, startLongitude: -5.4233455, city: 'gibraltar' }
  },
  {
    path: '/newbury',
    component: ReviewMap,
    props: { startLatitude: 51.3258069, startLongitude: -1.3997143, city: 'newbury' }
  },
  {
    path: '/kent',
    component: ReviewMap,
    props: { startLatitude: 51.250324, startLongitude: 0.513093, city: 'kent' }
  },
  {
    path: '/lisbon',
    component: ReviewMap,
    props: { startLatitude: 38.725309, startLongitude: -9.15489, city: 'lisbon' }
  },
  {
    path: '/vienna',
    component: ReviewMap,
    props: { startLatitude: 48.2311576, startLongitude: 16.3328914, city: 'vienna' }
  },
  {
    path: '/hiroshima',
    component: ReviewMap,
    props: { startLatitude: 34.3997772, startLongitude: 132.4144073, city: 'hiroshima' }
  },
  {
    path: '/kyoto',
    component: ReviewMap,
    props: { startLatitude: 34.998351, startLongitude: 135.754005, city: 'kyoto' }
  },
  {
    path: '/osaka',
    component: ReviewMap,
    props: { startLatitude: 34.6777115, startLongitude: 135.4036378, city: 'osaka' }
  },
  {
    path: '/tokyo',
    component: ReviewMap,
    props: { startLatitude: 35.6858317, startLongitude: 139.729271, city: 'tokyo' }
  },
  {
    path: '/sarlat-la-caneda',
    component: ReviewMap,
    props: { startLatitude: 44.8922057, startLongitude: 1.1933223, city: 'sarlat-la-caneda' }
  },
  {
    path: '/geneva',
    component: ReviewMap,
    props: { startLatitude: 46.2070021, startLongitude: 6.1496071, city: 'geneva' }
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/signout',
    component: Signout
  },
  {
    path: '/',
    component: CityPicker
  }
]

export default _.map(routes, route => {
  if (['/login'].includes(route.path)) {
    return route
  }
  return { ...route, component: authedComponent(route.component) }
})
