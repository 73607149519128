import React, { useState, useEffect } from 'react'
import Filters from './filters'
import ReviewMap from './reviewMap'
import { filterReviews, reviewsFeatureCollection } from '../data/reviews'
import { fetchReviews } from '../firebase/data'

export default props => {
  const [cityReviews, setCityReviews] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const fetchedReviews = await fetchReviews(props.city)
      console.log('fetchedReviews', fetchedReviews)
      setCityReviews(fetchedReviews)
    }
    fetchData()
  }, [])

  const [minStars, setMinStars] = useState(4.5)
  const [maxStars, setMaxStars] = useState(5)
  const [minReviewCount, setMinReviewCount] = useState(250)
  const [maxReviewCount, setMaxReviewCount] = useState(100000)
  const [prices, setPrices] = useState([null, undefined, 1, 2, 3, 4])
  const reviews = reviewsFeatureCollection(
    filterReviews(cityReviews, {
      minStars,
      maxStars,
      minReviewCount,
      maxReviewCount,
      prices
    })
  )
  console.log('reviews', reviews)
  console.log('cityReviews', cityReviews)
  return (
    <div className="flex flex-1 flex-col md:flex-row">
      <Filters
        minStars={minStars}
        maxStars={maxStars}
        minReviewCount={minReviewCount}
        maxReviewCount={maxReviewCount}
        prices={prices}
        onMinStarsChange={setMinStars}
        onMaxStarsChange={setMaxStars}
        onMinReviewCountChange={setMinReviewCount}
        onMaxReviewCountChange={setMaxReviewCount}
        onPricesChange={setPrices}
      />
      <ReviewMap reviews={reviews} startLatitude={props.startLatitude} startLongitude={props.startLongitude} />
    </div>
  )
}
