import _ from 'lodash'
import request from 'superagent'

const enrichReviews = rawReviewsData =>
  _.map(rawReviewsData, review => {
    return {
      ...review,
      establishmentType: _.chain(review.types)
        .map(type => _.startCase(type))
        .join(', ')
        .value()
    }
  })

export const fetchReviews = async reviewFileName => {
  const response = await request.get(`/reviews/${reviewFileName}`)
  return enrichReviews(response.body)
}

const reviewToFeature = review => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [review.longitude, review.latitude]
    },
    properties: review
  }
}

export const reviewsFeatureCollection = reviews => ({
  type: 'FeatureCollection',
  features: _.map(reviews, reviewToFeature)
})

export const filterReviews = (reviewsData, { minStars, maxStars, minReviewCount, maxReviewCount, prices }) => {
  return _.filter(reviewsData, review => {
    return (
      review.stars >= minStars &&
      review.stars <= maxStars &&
      review.reviews >= minReviewCount &&
      review.reviews <= maxReviewCount &&
      _.includes(prices, review.priceLevel)
    )
  })
}
