import { useEffect } from 'react'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'

export default () => {
  const history = useHistory()
  useEffect(() => {
    const mount = async () => {
      await firebase.auth().signOut()
      history.push('/login')
    }
    mount()
  }, [])
  return null
}
