import React from 'react'
import { Link } from 'react-router-dom'

const CityLink = ({ to, children }) => (
  <Link className="underline mb-4" to={to}>
    {children}
  </Link>
)

export default () => (
  <div className="flex flex-1 flex-col items-center justify-center text-center">
    <p className="text-3xl mb-8">Find the best reviewed places in your city</p>
    <CityLink to="/london">London</CityLink>
    <CityLink to="/nyc">New York</CityLink>
    <CityLink to="/boston">Boston</CityLink>
    <CityLink to="/gibraltar">Gibraltar</CityLink>
    <CityLink to="/newbury">Newbury</CityLink>
    <CityLink to="/kent">Kent</CityLink>
    <CityLink to="/vienna">Vienna</CityLink>
    <CityLink to="/cyprus">Cyprus</CityLink>
    <CityLink to="/lisbon">Lisbon</CityLink>
    <CityLink to="/hiroshima">Hiroshima</CityLink>
    <CityLink to="/kyoto">Kyoto</CityLink>
    <CityLink to="/osaka">Osaka</CityLink>
    <CityLink to="/tokyo">Tokyo</CityLink>
    <CityLink to="/sarlat-la-caneda">sarlat-la-Caneda</CityLink>
    <CityLink to="/geneva">Geneva</CityLink>
  </div>
)
