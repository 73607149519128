import _ from 'lodash'
import React, { useState } from 'react'
import ReactMapGL, { Source, Layer, Popup, NavigationControl, GeolocateControl } from 'react-map-gl'

const MAPBOX_TOKEN = 'pk.eyJ1IjoicmljaGFyZGdpbGwiLCJhIjoiY2l5bXdocDV2MDA2bzJxcGFlZWpmdzB1ciJ9.7eVyq2ry5cyxUGMXs6YnOg'

export default props => {
  const [viewport, setViewport] = useState({
    latitude: props.startLatitude,
    longitude: props.startLongitude,
    zoom: 12
  })
  const [isPopupOpen, setPopupOpen] = useState(false)

  const [currentReview, setCurrentReview] = useState(null)

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="100%"
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxApiAccessToken={MAPBOX_TOKEN}
      onViewportChange={setViewport}
      onClick={event => {
        const feature = _.find(event.features, f => f.source === 'reviews')
        if (feature) {
          setCurrentReview(feature)
          setPopupOpen(true)
        } else {
          setPopupOpen(false)
        }
      }}
    >
      <Source id="reviews" type="geojson" data={props.reviews}>
        <Layer
          id="point"
          type="circle"
          paint={{
            'circle-radius': 10,
            'circle-color': '#007cbf'
          }}
        />
      </Source>
      {isPopupOpen && (
        <Popup
          latitude={currentReview.geometry.coordinates[1]}
          longitude={currentReview.geometry.coordinates[0]}
          closeButton={true}
          closeOnClick={false}
          onClose={() => setPopupOpen(false)}
          anchor="top"
        >
          <div>{_.get(currentReview, 'properties.name')}</div>
          <div>{_.get(currentReview, 'properties.establishmentType')}</div>
          <div>{_.get(currentReview, 'properties.stars')} stars</div>
          <div>{_.get(currentReview, 'properties.reviews')} reviews</div>
          <div>{_.get(currentReview, 'properties.searchQuery')} Search Query</div>
          <a className="underline text-indigo-500" href={_.get(currentReview, 'properties.url')} target="_blank">
            Open in Google Maps
          </a>
        </Popup>
      )}
      <GeolocateControl
        onGeolocate={position => console.log(position)}
        onViewportChange={viewport => setViewport({ ...viewport, zoom: Math.min(viewport.zoom, 16) })}
        positionOptions={{ enableHighAccuracy: true }}
        fitBoundsOptions={{ maxZoom: 3 }}
        showUserLocation={true}
        style={{ position: 'absolute', right: 10, bottom: 105 }}
      />
      <div className="absolute right-2.5 bottom-10">
        <NavigationControl showCompass={false} />
      </div>
    </ReactMapGL>
  )
}
