import React from 'react'
import _ from 'lodash'

const ToggleButton = props => (
  <div
    isSelected={props.isSelected}
    onClick={props.onClick}
    className={`text-sm p-2 border-gray-500 border-l-[0.5px] border-y-[0.5px] w-14 text-center cursor-pointer ${props.isSelected &&
      'bg-cyan-400 font-bold'}`}
  >
    {props.label}
  </div>
)

const updateValues = (values, value) => (values.includes(value) ? _.without(values, value) : [...values, value])

const VALUES = [
  {
    label: 'None',
    value: undefined
  },
  {
    label: '£',
    value: 1
  },
  {
    label: '££',
    value: 2
  },
  {
    label: '£££',
    value: 3
  },
  {
    label: '££££',
    value: 4
  }
]

export default props => {
  return (
    <div className="flex flex-row border-gray-500 border-r-[0.5px]">
      {_.map(VALUES, ({ label, value }) => (
        <ToggleButton
          key={`toggle-${value}`}
          label={label}
          isSelected={props.prices.includes(value)}
          onClick={() => props.onValuesChange(updateValues(props.prices, value))}
        />
      ))}
    </div>
  )
}
