import React, { useState } from 'react'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'
import Form from '../core/form'

const signIn = async password => firebase.auth().signInWithEmailAndPassword('richard+reviewgems@rgill.co.uk', password)

export default props => {
  const [password, setPassword] = useState('')
  const history = useHistory()
  return (
    <div className="flex flex-1 item-center justify-center">
      <Form className="flex flex-col item-center justify-center">
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          className="mx-2 text-center w-36 py-1 px-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block border-[0.5px] border-gray-500 rounded-md"
        />
        <input
          className="mt-4 inline-flex text-center items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          type="submit"
          value="Login"
          onClick={async () => {
            await signIn(password)
            history.push('/')
          }}
        />
      </Form>
    </div>
  )
}
