import firebase from 'firebase/app'
import 'firebase/auth'

let userLoaded = false

export const getCurrentUser = auth => {
  return new Promise((resolve, reject) => {
    if (userLoaded) {
      resolve(firebase.auth().currentUser)
    }
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      userLoaded = true
      unsubscribe()
      resolve(user)
    }, reject)
  })
}
