import React from 'react'
import { classNames } from '../core/overrides'
import PriceToggleButton from '../core/priceToggleButton'

const Input = props => (
  <input
    {...props}
    className={classNames(
      'mx-2 w-16 py-1 px-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-[0.5px] border-gray-500 rounded-md',
      props.className
    )}
  />
)

export default props => {
  return (
    <div className="flex flex-col m-4">
      <div className="flex flex-1 flex-col">
        <div className="flex items-center mb-4 md:mb-10">
          <div>Stars:</div>
          <Input type="number" step="0.1" min="0" max="20" value={props.minStars} onChange={e => props.onMinStarsChange(e.target.value)} />
          <div>to</div>
          <Input type="number" step="0.1" min="0" max="5" value={props.maxStars} onChange={e => props.onMaxStarsChange(e.target.value)} />
        </div>
        <div className="flex items-center mb-4 md:mb-10">
          <div>Review count:</div>
          <Input
            type="number"
            step="50"
            min="0"
            value={props.minReviewCount}
            onChange={e => props.onMinReviewCountChange(e.target.value)}
          />
          <div>to</div>
          <Input
            className="w-24"
            type="number"
            step="100"
            min="0"
            value={props.maxReviewCount}
            onChange={e => props.onMaxReviewCountChange(e.target.value)}
          />
        </div>
        <div className="flex items-center">
          <label className="pr-4 hidden md:flex">Price:</label>
          <PriceToggleButton prices={props.prices} onValuesChange={prices => props.onPricesChange(prices)} />
        </div>
      </div>
    </div>
  )
}
