import firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBIq2tMbZh_87RHfrBtqKTwULbl2ZXpUuM',
  authDomain: 'review-gems.firebaseapp.com',
  databaseURL: 'https://review-gems.firebaseio.com',
  projectId: 'review-gems',
  storageBucket: 'review-gems.appspot.com',
  messagingSenderId: '473817582564',
  appId: '1:473817582564:web:6ca30cedad6eced001d5f1'
}

firebase.initializeApp(firebaseConfig)
