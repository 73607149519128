import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getCurrentUser } from '../firebase/auth'

const AuthorizedRoute = props => {
  const history = useHistory()
  const [isUserLoaded, setIsUserLoaded] = useState(false)
  useEffect(() => {
    const mount = async () => {
      const currentUser = await getCurrentUser()
      if (!currentUser) {
        history.push('/login')
      } else {
        setIsUserLoaded(true)
      }
    }
    mount()
  }, [])
  return isUserLoaded ? props.children : null
}

export const authedComponent = Component => {
  return props => (
    <AuthorizedRoute {...props}>
      <Component {...props} />
    </AuthorizedRoute>
  )
}
